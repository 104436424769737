import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { isAdmin, isClient, isSeniorStaff, isStaff } from '../../lib/helper/authorisation';
import { niceDateTimeFormatter, userNameFormatter } from '../../lib/helper/formatting';
import * as PropTypes from 'prop-types';
import { NOTE_TYPES } from '../../lib/helper/general';
import HoverableText from './HoverableText';
import { invoiceBatchNoteSnipAt } from '../../lib/redux/actions/invoiceBatchNote';
import { connect } from 'react-redux';

const mapDispatchToProps = {
  invoiceBatchNoteSnipAt,
};

class InvoiceBatchNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snipAtIndex: props.note.snipAtIndex ?? false,
      enableSnip: false,
    };
  }

  renderNoteBadges(note) {
    if (note?.noteType?.code === NOTE_TYPES.CASE_CLOSED) {
      return (
        <Badge variant={'success'} className="text-uppercase mr-1">
          Embassy - Case Closed
        </Badge>
      );
    }
    if (note?.noteType?.code === NOTE_TYPES.CHASER) {
      return (
        <Badge variant={'warning'} className="text-uppercase mr-1">
          Embassy Case Chased
        </Badge>
      );
    }
  }

  render() {
    const { note, deleteNote } = this.props;
    if (isClient() && note.isPrivate) {
      return null;
    }

    const handleLineClick = ({ index }) => {
      this.setState({ snipAtIndex: index, enableSnip: false });
      this.props.invoiceBatchNoteSnipAt(index, note.id);
    };

    return (
      <div className="invoice-batch-single__note">
        <p className="mb-1">
          {note.isPrivate ? (
            <Badge variant={'light'} className="text-uppercase mr-1">
              Internal Note
            </Badge>
          ) : (
            <Badge variant={'danger'} className="text-uppercase mr-1">
              Public Note
            </Badge>
          )}
          {note.isClient && (
            <Badge variant={'info'} className="text-uppercase mr-1">
              Client Note
            </Badge>
          )}
          {this.renderNoteBadges(note)}
          {((note.isPrivate && isSeniorStaff()) || isAdmin()) && (
            <span
              className="action-icon ml-2"
              onClick={() => {
                deleteNote(note.id);
              }}>
              <span className="sr-only">Delete</span>
              <i className="fas fa-trash" title="Delete note" />
            </span>
          )}
          {!isClient() && (
            <span className="action-icon ml-2" onClick={() => this.setState({ enableSnip: !this.state.enableSnip })}>
              <span className="sr-only">Shorten note text</span>
              {!this.state.enableSnip ? (
                <i className="fas fa-cut" title="Shorten note text" />
              ) : (
                <i className="fas fa-undo" title="Stop shortening note text" />
              )}
            </span>
          )}
        </p>
        <p className="invoice-batch-single__note-heading">
          {niceDateTimeFormatter(note.createdAt)} -{' '}
          <strong>
            {!isClient() ?
                userNameFormatter(note.createdBy) : (
                  !note?.createdBy?.roles?.some((role) => ['ROLE_CHASER_OUTSOURCER', 'ROLE_CHASER_OUTSOURCER_PLUS'].includes(role)) ?
                    userNameFormatter(note.createdBy) :
                    'MediAccounts Admin'
                )
            }
          </strong>{' '}
          said:
        </p>

        <HoverableText note={note} onLineClick={handleLineClick} snipAtIndex={this.state.snipAtIndex} enableSnip={this.state.enableSnip} />

        {note?.sentToUsersString && isStaff() ? <p className="font-italic">This note was sent to: {note.sentToUsersString}</p> : null}
      </div>
    );
  }
}

InvoiceBatchNote.propTypes = {
  note: PropTypes.object.isRequired,
  deleteNote: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(InvoiceBatchNote);
