import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { invoiceBatchListFetch, invoiceBatchListUnload } from '../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import { Alert, Row, Col } from 'react-bootstrap';
import { invoiceBatchIdFromIri, niceDateFormatter, timeOfDayText } from '../../lib/helper/formatting';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';

const mapStateToProps = (state) => ({
  ...state.invoiceBatchList,
});

const mapDispatchToProps = {
  invoiceBatchListFetch,
  invoiceBatchListUnload,
};

class PotentialDuplicates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      potentialDuplicates: [],
      //      checkedKeys: []
    };
    //    this.checkDupes();
  }

  _mergeArraysOfObjectsDeDuped(x, y) {
    let x1 = x;
    if (!Array.isArray(x)) {
      x1 = [];
    }
    let y1 = y;
    if (!Array.isArray(y)) {
      y1 = [];
    }
    return x1.concat(y1).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  }

  componentDidMount() {
    this.checkDupes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hospitals !== this.props.hospitals) {
      this.checkDupes();
    } else if (prevProps.clinicDates !== this.props.clinicDates) {
      this.checkDupes();
    } else if (prevProps.dateAmPmMap !== this.props.dateAmPmMap) {
      this.checkDupes();
    }

    if (prevProps.invoiceBatches !== this.props.invoiceBatches) {
      const { dateAmPmMap = false, currentBatch = false } = this.props;
      // check that all of the batches have an invoiceDate that has a matching date and the related timeOfDay (and that they aren't the currentbatch if supplied)
      this.setState((prevState) => {
        const filteredDuplicates = this._mergeArraysOfObjectsDeDuped(prevState.potentialDuplicates, this.props.invoiceBatches).filter((i) => {
          return (
            (!currentBatch || currentBatch.id !== i.id) &&
            i.invoiceDates.some((d) => {
              return this.props.clinicDates.some((cd) => {
                const timeOfDay = dateAmPmMap !== false ? this.props.dateAmPmMap[cd.key] : cd.timeOfDay;
                return new Date(cd.date).toDateString() === new Date(d.date).toDateString() && timeOfDay === d.timeOfDay;
              });
            })
          );
        });

        // mark the date that matches for highlighting
        filteredDuplicates.map((i) => {
          return i.invoiceDates.map((d) => {
            d.isMatch = this.props.clinicDates.some((cd) => {
              const timeOfDay = dateAmPmMap !== false ? this.props.dateAmPmMap[cd.key] : cd.timeOfDay;
              return new Date(cd.date).toDateString() === new Date(d.date).toDateString() && timeOfDay === d.timeOfDay;
            });
            return d;
          });
        });

        return {
          potentialDuplicates: filteredDuplicates,
        };
      });
    }
  }

  checkDupes() {
    const { consultantIRI, hospitals = [], clinicDates = [], invoiceBatchListFetch, invoiceBatchListUnload, dateAmPmMap = false } = this.props;

    const filters = {};
    filters['consultant.id'] = consultantIRI;

    if (hospitals.length > 0) {
      filters['hospitals.id'] = hospitals.map((h) => {
        return h.value;
      });
    }

    if (clinicDates.length > 0 && hospitals.length > 0) {
      clinicDates.forEach((clinicDate) => {
        //        const key = filters['consultant.id'] + '-' + (filters['hospitals.id'] ? filters['hospitals.id'].join('|') + '-' : '' ) +  moment(clinicDate.date).format('YYYY-MM-DD') + '-' + (dateAmPmMap !== false ? dateAmPmMap[clinicDate.key] : clinicDate.timeOfDay);
        //        this.setState(prevState => ({
        //          checkedKeys: [...prevState.checkedKeys, key]
        //        }));
        filters['invoiceDates.date(after)'] = moment(clinicDate.date).format('YYYY-MM-DD');
        filters['invoiceDates.date(strictly_before)'] = moment(clinicDate.date).add(1, 'day').format('YYYY-MM-DD');
        filters['invoiceDates.timeOfDay'] = dateAmPmMap !== false ? dateAmPmMap[clinicDate.key] : clinicDate.timeOfDay;
        invoiceBatchListFetch(1, filters, true);
      });
    } else {
      invoiceBatchListUnload();
    }
  }

  render() {
    if (this.state.potentialDuplicates.length < 1) {
      return null;
    }

    // remove any splitFrom/To batches
    const filteredDupes =
      this.props?.currentBatch?.splitFromInvoiceBatch || this.props?.currentBatch?.splitToInvoiceBatch
        ? this.state.potentialDuplicates.filter(
            (pd) =>
              pd.id !== invoiceBatchIdFromIri(this.props?.currentBatch?.splitToInvoiceBatch) &&
              pd.id !== invoiceBatchIdFromIri(this.props?.currentBatch?.splitFromInvoiceBatch),
          )
        : this.state.potentialDuplicates;
    if (filteredDupes.length < 1) {
      return null;
    }
    return (
      <Alert variant="warning" className="potential-duplicates">
        <h6>{filteredDupes.length} Potential duplicate Invoice Batch/Upload found:</h6>
        <ul
          className="mt-2 list-unstyled mb-0"
          style={{
            maxHeight: '200px',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          {filteredDupes.map((i, idx) => {
            return (
              <li key={idx} className="mt-2">
                <p>
                  Invoice Batch <strong>{i.id}</strong> uploaded on {niceDateFormatter(i.createdAt)}
                  <Link to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', i.id)} target="_blank" className="btn btn-sm btn-warning ml-2">
                    View Batch
                  </Link>
                </p>
                <Row>
                  <Col sm={12} md={6}>
                    Hospital(/s):{' '}
                    <ul className="list-unstyled">
                      {i.hospitals.map((h) => (
                        <li key={h.id}>{h.name}</li>
                      ))}
                    </ul>
                  </Col>
                  <Col sm={12} md={6}>
                    Clinic Date(/s):
                    <ul className="list-unstyled">
                      {i.invoiceDates.map((d) => (
                        <li key={d.id} className={d.isMatch ? 'font-weight-bold' : ''}>
                          {niceDateFormatter(d.date) + ' ' + timeOfDayText(d.timeOfDay)}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </li>
            );
          })}
        </ul>
      </Alert>
    );
  }
}

PotentialDuplicates.propTypes = {
  consultantIRI: PropTypes.string.isRequired,
  clinicDates: PropTypes.array.isRequired,
  hospitals: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PotentialDuplicates);
