import React, { Fragment, useState } from 'react';

const HoverableText = ({ note, onLineClick, snipAtIndex = false, enableSnip = false }) => {
  const [showText, setShowText] = useState(false);
  const handleLineClick = (index) => {
    onLineClick({ index });
    setShowText(false);
  };

  return (
    <>
      <p className={`invoice-batch-single__note-content ${enableSnip ? 'snippable' : ''}`}>
        {note.note.split('\n').map((item, index) => (
          <Fragment key={index}>
            <span
              className={`hoverable-line ${!showText && !enableSnip && !!snipAtIndex && index >= snipAtIndex ? 'd-none' : ''}`}
              onClick={() => handleLineClick(index)}>
              {item}
            </span>
            <br className={!showText && !enableSnip && !!snipAtIndex && index >= snipAtIndex ? 'd-none' : ''} />
          </Fragment>
        ))}
      </p>
      {!showText && !enableSnip && snipAtIndex !== false && (
        <button className={'btn btn-xs btn-info w-auto'} onClick={() => setShowText(!showText)}>
          Show Hidden Note Text
        </button>
      )}
    </>
  );
};

export default HoverableText;
