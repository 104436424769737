import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { userSingleFetch, userSingleUpdate, userProfileFetch, userSingleDisable2FA, userRequestResetLink } from '../../../lib/redux/actions/user';
import MASpinner from '../../common/MASpinner';
import { renderCheckbox, renderField, renderSelect } from '../../../lib/helper/form';
import { isAdmin,  isLoggedInUser, isSeniorStaff } from '../../../lib/helper/authorisation';
import Alert from 'react-bootstrap/Alert';
import { roleOptions } from '../../../lib/helper/general';

const mapDispatchToProps = {
  userSingleFetch,
  userSingleUpdate,
  userProfileFetch,
  userSingleDisable2FA,
  userRequestResetLink,
  addToast,
};

const singularRoles = [
  'ROLE_SUBUSER', 'ROLE_PRIMARY_USER', 'ROLE_STAFF', 'ROLE_SENIOR_STAFF', 'ROLE_ADMIN'
]

class UserDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    const { user, initialize, extended = true } = this.props;
    const initValues = user
      ? {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        }
      : {};
    if (extended) {
      initValues.isEnabled = user ? user.isEnabled : true;
      initValues.roles = user ? user.roles.filter((r) => {
            return r !== 'ROLE_USER';
      }) : null;

      if (!Array.isArray(initValues.roles)) {
        initValues.roles = [initValues.roles];
      }
    }

    this.state = {
      resetting2fa: false,
    };

    initialize(initValues);
  }

  onSubmit(values) {
    const { userSingleUpdate, user = undefined, addToast, isCreate = false, onCreate = undefined, extended = true } = this.props;

    if (values.roles && !Array.isArray(values.roles)) {
      values.roles = [values.roles];
    }

    if (values.roles.length > 1 && values.roles.some(r => singularRoles.includes(r)) ) {
      throw new SubmissionError({_error: 'You can only have multiple roles for Outsourcers'});
    }

    if (Array.isArray(values.roles) && values.roles.length === 0) {
      delete values.roles;
    }

    values.isEnabled = Boolean(values.isEnabled);

    if (isCreate && onCreate) {
      return onCreate(values);
    } else if (user) {
      if (!extended) {
        delete values.roles;
        delete values.isEnabled;
      }

      return userSingleUpdate(values, user.id).then(() => {
        addToast('The user was successfully updated', true);
      });
    }
  }

  linkToClipBoard() {
    const { passwordLink = false, addToast } = this.props;

    if (navigator?.clipboard) {
      navigator.clipboard.writeText(passwordLink);
      addToast('Link Copied', true, false);
    }
  }

  getExtendedFields() {
    const { isCreate } = this.props;
    return (
      <>
          <Field name="roles"  label="User Role" type="select" options={roleOptions} multiple={true} component={renderSelect} showEmpty={!!isCreate}
          helpText='You can only have multiple roles for Outsourcers. Hold ctrl/cmd + click to select multiple.'
          />
        <Field name="isEnabled" label="Active Account?" id="is-enabled" component={renderCheckbox} className="mb-4" />
      </>
    );
  }

  disable2fa() {
    if (!this.state.resetting2fa) {
      this.setState(() => {
        return { resetting2fa: true };
      });
    } else {
      return this.props.userSingleDisable2FA(this.props.user.id).then(() => {
        this.props.addToast('2FA reset successfully', true);
        this.setState(() => {
          return { resetting2fa: false };
        });
      });
    }
  }

  render() {
    const {
      handleSubmit,
      error,
      submitting,
      isUpdatingUser,
      isFetchingUser,
      title = 'User Details',
      user,
      extended = true,
      isCreate = false,
      userRequestResetLink,
      passwordLink = false,
    } = this.props;
    if (isFetchingUser) {
      return <MASpinner />;
    }

    return (
      <>
        <div className="crud__section mb-3">
          <div className="crud__section-icon">
            <i className="fas fa-user" />
          </div>
          <h2 className="crud__section-header">{title}</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field name="email" label="Email Address" type="text" component={renderField} />
            <Field name="firstName" label="First Name" type="text" component={renderField} />
            <Field name="lastName" label="Last Name" type="text" component={renderField} />

            {isSeniorStaff() && extended && this.getExtendedFields()}

            {isUpdatingUser ? (
              <MASpinner />
            ) : (
              <button type="submit" disabled={submitting} className="btn btn-primary btn-block">
                {isCreate ? 'Create User' : 'Update'}
              </button>
            )}
            {isUpdatingUser ? (
              <MASpinner />
            ) : !isCreate && isAdmin() && user && !passwordLink ? (
              <>
                <hr />
                <button disabled={submitting} className="btn btn-secondary btn-block mt-3" onClick={() => userRequestResetLink(user.email, !user.hasPassword)}>
                  Generate Password Link
                </button>
              </>
            ) : null}
          </Form>
        </div>
        {passwordLink && (
          <Alert variant="info" className="mb-3 mt-2">
            <div>
              <h5>Share this password reset link</h5>
              <p className="mb-2 font-weight-bold" title="Click to copy link" role="button">
                <i className="fas fa-copy mr-2" /> <span onClick={this.linkToClipBoard.bind(this)}>{passwordLink}</span>
              </p>
              {isAdmin() && <p className="mb-0 font-italic">N.B. If you are using this link to set a password on behalf of a User, you must log out first.</p>}
            </div>
          </Alert>
        )}

        {!isCreate && user && (isAdmin() || isLoggedInUser(user.id)) && (
          <div className="crud__section">
            <div className="crud__section-icon">
              <i className="fas fa-qrcode" />
            </div>
            <h2 className="crud__section-header">Reset the 2FA code</h2>
            <p>If you have lost access to your authentication application, you can reset this up below.</p>
            <button onClick={this.disable2fa.bind(this)} className={`btn ${this.state.resetting2fa ? 'btn-danger' : 'btn-outline-danger'}`} type="button">
              {this.state.resetting2fa ? 'Rescan the QR code next time you log in. Click again to continue...' : 'Reset 2fa code'}
            </button>
          </div>
        )}
      </>
    );
  }
}

export default reduxForm({
  form: 'UserDetailsForm',
})(connect(null, mapDispatchToProps)(UserDetailsForm));
