import React from 'react';

export const CCSD_CODE_TYPE_CONSULTATION_CODE = 'consultation';
export const CCSD_CODE_TYPE_PROCEDURE_CODE = 'procedure';

export const NOTE_TYPES = {
  GENERAL: 'GEN',
  CASE_CLOSED: 'ECC',
  CHASER: 'ECH',
};

export const jspp = (jsonToOutput) => {
  return <pre>{JSON.stringify(jsonToOutput, null, 2)}</pre>;
};

export const sortHealthInsurersByName = (a, b) => {
  if (a.healthInsurer.canHaveOverrideLabel) {
    return 1;
  }
  if (b.healthInsurer.canHaveOverrideLabel) {
    return -1;
  }
  if (a.healthInsurer.isAnalogue) {
    return 0;
  }
  if (b.healthInsurer.isAnalogue) {
    return 1;
  }
  if (a.healthInsurer.name < b.healthInsurer.name) {
    return -1;
  }
  if (a.healthInsurer.name > b.healthInsurer.name) {
    return 1;
  }
  return 0;
};

const JODIT_EDITOR_HEIGHT = 600;
const JODIT_EDITOR_READ_ONLY = false;
const JODIT_EDITOR_BUTTONS = `undo,redo,|,bold, italic,underline,strikethrough,|,font,fontsize,brush,|,indent,outdent,|,ul,ol,|,superscript,subscript,eraser,|,table,|,fullsize,print`;
export const JODIT_EDITOR_CONFIG = {
  readonly: JODIT_EDITOR_READ_ONLY,
  placeholder: 'Start typing...',
  enableDragAndDropFileToEditor: false,
  showPlaceholder: false,
  image: { openOnDblClick: false },
  toolbarButtonSize: 'small',
  minWidth: '100%',
  tabIndex: 0,
  height: JODIT_EDITOR_HEIGHT,
  minHeight: JODIT_EDITOR_HEIGHT,
  //        allowResizeY: false,
  beautify: false,
  beautifyHTMLCDNUrlsJS: [],
  sourceEditorCDNUrls: [],
  showCharsCounter: !JODIT_EDITOR_READ_ONLY,
  showWordsCounter: !JODIT_EDITOR_READ_ONLY,
  showXPathInStatusbar: !JODIT_EDITOR_READ_ONLY,
  controls: {
    //          fullsize: { exec: executeToggle, isActive: updateToggle, tooltip: "Full Screen" },
    attachFile: {
      //            exec: attachFiles,
      icon: 'upload',
      tooltip: 'Attach Files',
    },
  },
  buttons: JODIT_EDITOR_BUTTONS,
  buttonsMD: JODIT_EDITOR_BUTTONS,
  buttonsSM: JODIT_EDITOR_BUTTONS,
  buttonsXS: JODIT_EDITOR_BUTTONS,
  //        events: { paste: onPaste, drop: onDrop },
};


export const roleOptions = [
  { value: 'ROLE_ADMIN', text: 'Admin' },
  { value: 'ROLE_SENIOR_STAFF', text: 'Senior Staff' },
  { value: 'ROLE_STAFF', text: 'Staff' },
  { value: 'ROLE_OUTSOURCER', text: 'Outsourcer' },
  { value: 'ROLE_CHASER_OUTSOURCER_PLUS', text: 'Chaser Outsourcer Plus' },
  { value: 'ROLE_CHASER_OUTSOURCER', text: 'Chaser Outsourcer' },
  { value: 'ROLE_PRIMARY_USER', text: 'Consultant' },
  { value: 'ROLE_SUBUSER', text: 'Consultant Sub User' },
];
