import {
  INVOICE_BATCH_SINGLE_REQUEST,
  INVOICE_BATCH_SINGLE_RECEIVED,
  INVOICE_BATCH_SINGLE_ERROR,
  INVOICE_BATCH_SINGLE_UPDATED,
  INVOICE_BATCH_SINGLE_UPDATE_REQUEST,
  INVOICE_BATCH_NOTE_ADD_REQUEST,
  INVOICE_BATCH_NOTE_ADDED,
  INVOICE_BATCH_NOTE_DELETED,
  INVOICE_BATCH_SINGLE_STATUS_CHANGE_REQUEST,
  INVOICE_BATCH_SINGLE_STATUS_CHANGE_ERROR,
  INVOICE_BATCH_SINGLE_STATUS_CHANGED,
  INVOICE_BATCH_NOTES_RECEIVED,
  INVOICE_BATCH_AUDIT_LOG_RECEIVED,
  INVOICE_BATCH_SINGLE_UNLOAD,
  INVOICE_BATCH_AUDIT_LOG_UNLOAD,
  CONSULTANT_SINGLE_RECEIVED, INVOICE_BATCH_NOTE_UPDATED,
} from '../constants';

let existingConsultantInState;
let updateIncludesConsultant;
let newInvoiceBatchState;

const reducer = (
  state = {
    invoiceBatch: null,
    invoiceBatchNotes: [],
    invoiceBatchAuditLog: null,
    isFetchingInvoiceBatch: false,
    isUpdatingInvoiceBatch: false,
    isAddingInvoiceBatchNote: false,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANT_SINGLE_RECEIVED:
      if (state?.invoiceBatch?.consultant?.id === action?.data?.id) {
        return {
          ...state,
          invoiceBatch: { ...state.invoiceBatch, consultant: action.data },
        };
      }
      return state;
    case INVOICE_BATCH_SINGLE_UNLOAD:
      return {
        invoiceBatch: null,
        invoiceBatchNotes: [],
        invoiceBatchAuditLog: null,
        isFetchingInvoiceBatch: false,
        isUpdatingInvoiceBatch: false,
        isAddingInvoiceBatchNote: false,
      };
    case INVOICE_BATCH_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingInvoiceBatch: true,
      };

    case INVOICE_BATCH_SINGLE_RECEIVED:
      existingConsultantInState = state?.invoiceBatch?.consultant?.id;
      updateIncludesConsultant =  action?.data?.consultant?.id;
      newInvoiceBatchState = existingConsultantInState && (existingConsultantInState === updateIncludesConsultant) ? {...action.data, consultant: {...state.invoiceBatch.consultant, ...action.data.consultant} } : action.data;
      return {
        ...state,
        invoiceBatch: newInvoiceBatchState,
        isFetchingInvoiceBatch: false,
      };

    case INVOICE_BATCH_AUDIT_LOG_RECEIVED:
      return {
        ...state,
        invoiceBatchAuditLog: action.auditLog,
        isFetchingInvoiceBatch: false,
      };

    case INVOICE_BATCH_AUDIT_LOG_UNLOAD:
      return {
        ...state,
        invoiceBatchAuditLog: null,
        isFetchingInvoiceBatch: false,
      };

    case INVOICE_BATCH_SINGLE_STATUS_CHANGE_ERROR:
    case INVOICE_BATCH_SINGLE_ERROR:
      return {
        ...state,
        isFetchingInvoiceBatch: false,
        isUpdatingInvoiceBatch: false,
      };

    case INVOICE_BATCH_SINGLE_STATUS_CHANGE_REQUEST:
    case INVOICE_BATCH_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingInvoiceBatch: true,
      };
      return state;

    case INVOICE_BATCH_SINGLE_STATUS_CHANGED:
    case INVOICE_BATCH_SINGLE_UPDATED:
//      existingConsultantInState = state?.invoiceBatch?.consultant?.id;
//      updateIncludesConsultant =  action?.data?.consultant?.id;
//      newInvoiceBatchState = existingConsultantInState && (existingConsultantInState === updateIncludesConsultant) ? {...action.data, consultant: {...state.invoiceBatch.consultant, ...action.data.consultant} } : action.data;
      return {
        ...state,
        invoiceBatch: action.data,
        isUpdatingInvoiceBatch: false,
      };

    case INVOICE_BATCH_NOTE_ADD_REQUEST:
      return {
        ...state,
        isAddingInvoiceBatchNote: true,
      };

    case INVOICE_BATCH_NOTE_ADDED:
      return {
        ...state,
        invoiceBatchNotes: [...state.invoiceBatchNotes, action.data],
        isAddingInvoiceBatchNote: false,
      };
    case INVOICE_BATCH_NOTE_UPDATED:

      const updatedBatchNotesList = [...state.invoiceBatchNotes];
      const removeIndex = updatedBatchNotesList.map((item) => item.id).indexOf(action.data.id);

      if (removeIndex >= 0) {
        updatedBatchNotesList.splice(removeIndex, 1);
        updatedBatchNotesList.push(action.data);
      }

      return {
        ...state,
        invoiceBatchNotes: updatedBatchNotesList.sort((a, b) => b.id - a.id)
      };

    case INVOICE_BATCH_NOTE_DELETED:
      return {
        ...state,
        invoiceBatchNotes: [
          ...state.invoiceBatchNotes.filter(function (obj) {
            return obj.id !== action.id;
          }),
        ],
      };

    case INVOICE_BATCH_NOTES_RECEIVED:
      //      const invoiceBatch = {...state.invoiceBatch};
      //      if (invoiceBatch && invoiceBatch.id && invoiceBatch.id === action.invoiceBatchId) {
      //        invoiceBatch.invoiceBatchNotes = action.data['hydra:member'];
      //      }
      return {
        ...state,
        invoiceBatchNotes: action.data['hydra:member'],
      };

    default:
      return state;
  }
};

export default reducer;
