import React from 'react';
import Form from 'react-bootstrap/Form';
import { reduxForm } from 'redux-form';
import MASpinner from '../../common/MASpinner';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { invoiceAssignToUser } from '../../../lib/redux/actions/invoices';
import AssignToUserTypeahead from '../../fields/AssignToUserTypeahead';
import { userNameFormatter } from '../../../lib/helper/formatting';

const mapDispatchToProps = {
  invoiceAssignToUser,
  addToast,
};
const mapStateToProps = (state, props) => ({});

class InvoiceAssignUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clearTypeaheads: false,
      selectedAssignedToUser: props.invoice?.assignedTo ?? false,
      isEscalateConfirmedChecked: false,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  componentDidMount() {
    this.setState(() => {
      return { selectedAssignedToUser: this.props.invoice && this.props.invoice.assignedTo ? this.props.invoice.assignedTo : false };
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoice.id !== this.props.invoice.id || prevProps.invoice.assignedTo !== this.props.invoice.assignedTo) {
      this.setState(() => {
        return { selectedAssignedToUser: this.props.invoice && this.props.invoice.assignedTo ? this.props.invoice.assignedTo : false };
      });
    }
  }

  onSubmit() {
    const { invoiceAssignToUser, invoice, addToast, reset } = this.props;

    return invoiceAssignToUser(invoice.id, (this.state.selectedAssignedToUser ? this.state.selectedAssignedToUser['@id'] : null)).then(() => {
      addToast('The Invoice was successfully updated', true);
      reset();
    });
  }

  render() {
    const { handleSubmit, isUpdatingInvoice, submitting, pristine } = this.props;

    return (
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <AssignToUserTypeahead
              label={'Assigned to User'}
              rolesToShow='["ROLE_ADMIN","ROLE_SENIOR_STAFF","ROLE_STAFF","ROLE_CHASER_OUTSOURCER"]'
              selectedUsers={this.state.selectedAssignedToUser ? [this.state.selectedAssignedToUser] : []}
              shouldClear={this.state.clearTypeaheads}
              hasCleared={this.typeaheadCleared.bind(this)}
              name="assignedTo"
              multiple={false}
              labelKey={(option) => userNameFormatter(option, false)}
              onChange={(selected) => {
                this.setState(() => {
                  return { selectedAssignedToUser: selected[0] };
                });
              }}
          />

          {isUpdatingInvoice ? <MASpinner/> : null}
          {!isUpdatingInvoice && !pristine ? (
              <button type="submit" disabled={submitting} className="btn btn-primary">
                Update
              </button>
          ) : null}
        </Form>
    );
  }
}

InvoiceAssignUserForm.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
      form: 'InvoiceAssignUserForm',
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(InvoiceAssignUserForm),
);
